import React from "react";
import { HeadingWithLine } from "../../style/Heading/HeadingWithLine";
import { CardImage } from "../Card/CardImage";
import { BackgroundImage } from "../../style/BackgroundImage/BackgroundImage";
import { Image } from "../../style/Image";
import nbsp from "../../utils/nbsp";

const Card = ({ link, content, image, type, ...props }) => {
  const width = [
    "100%",
    null,
    "calc(50% - 34px)",
    null,
    type === "event" ? "30%" : null,
  ];
  return (
    <CardImage
      to={link}
      margin="3"
      padding="3"
      mb={["1", null, "3", null, null]}
      minHeight={["217px", null, "271px", null, null]}
      flexDirection={["column", null, null, null, null]}
      width={width}
    >
      {image?.childImageSharp && (
        <BackgroundImage>
          <Image image={image} cover inCard />
        </BackgroundImage>
      )}
      <HeadingWithLine
        pb={["3", null, null, null, null]}
        as="h2"
        fontSize={["18px", null, "24px", null, null]}
        color={["18px", null, null, null, null]}
        lineHeight={["3", null, null, null, null]}
        fontWeight="300"
      >
        {nbsp(content.title)}
      </HeadingWithLine>
      {props.children}
    </CardImage>
  );
};

export default Card;
