import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import Recaptcha from "react-recaptcha";
import { Flex } from "../../style/Grid";
import { Label } from "./Label";
import { Transition } from "./Transition";
import { Text } from "../../style/Text";
import { Checkbox } from "../../style/Checkbox/Checkbox";
import Button from "./Button";
import BtnComponent from "./../Button";
import { URL } from "../../constants";
import nbsp from "../../utils/nbsp";

import getStyledLink from "../../style/Link/getStyledLink";
const Link = getStyledLink("a");

const initialState = {
  name: "",
  email: "",
  department: "",
  city: "",
  description: "",
  linkedinLink: "",
  biography: "",
  gdpr: false,
  recaptcha: "",
};

const contactSchema = yup.object().shape({
  name: yup.string().required("Vyplň jméno a příjmení"),
  email: yup.string().email("Vyplň validní e-mail").required("Vyplň e-mail"),
  department: yup.string(),
  city: yup.string(),
  description: yup.string(),
  linkedinLink: yup.string(),
  biography: yup.mixed(),
  gdpr: yup.bool().oneOf([true], "Musíš souhlasit s podmínkami"),
});

const ContactFormMutation = async (values) => {
  try {
    const { biography, ...restVals } = values;

    return new Promise((resolveP, rejectP) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        if (values.biography) {
          reader.readAsDataURL(values.biography);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        } else {
          resolve(null);
        }
      }).then((file) => {
        let b = null;
        if (biography && file) {
          b = {
            filename: biography.name,
            file,
          };
        }
        fetch("/.netlify/functions/sendmail", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...restVals,
            biography: b,
          }),
        }).then((response) => {
          if (response.ok) {
            resolveP(response.json());
          } else {
            resolveP(response);
          }
        });
      });
    });
  } catch (e) {
    return e;
  }
};

let recaptchaInstance = {};

const executeCaptcha = () => {
  recaptchaInstance.reset();
  recaptchaInstance.execute();
};

const ContactForm = ({ handleShowModal, showModal }) => {
  const [sent, setSent] = useState(false);

  return (
    <Transition
      style={
        showModal
          ? { opacity: 1, visibility: "visible" }
          : { opacity: 0, visibility: "hidden" }
      }
    >
      <Transition
        style={
          showModal
            ? {
                opacity: 1,
                visibility: "visible",
                transition: "all 200ms linear",
              }
            : {
                opacity: 0,
                visibility: "hidden",
                transition: "all 200ms linear",
              }
        }
      >
        <Flex
          position="fixed"
          top="50%"
          left="50%"
          zIndex="9999"
          style={{ transform: "translate(-50%, -50%)" }}
          width="552px"
          maxWidth="100%"
        >
          <Flex
            flexDirection="column"
            width={["100%", null, null, null, null]}
            maxWidth="550px"
            justifyContent="center"
            background="white"
          >
            <Text
              flexDirection="row"
              padding={["24px", null, null, "48px", null]}
              paddingLeft=""
              as="p"
              color="EYOffBlack.default"
              fontWeight="light"
              backgroundColor="EYYellow.default"
              fontSize={[2, null, null, 5, 5]}
              lineHeight={["24px", null, null, "32px", "32px"]}
              position="relative"
              height={["72px", null, null, "126px", null]}
            >
              {sent
                ? "Ďekujeme, že u nás chceš pracovat"
                : "Nech nám na sebe kontakt"}
              <Flex
                as="button"
                fontSize={2}
                position="absolute"
                display="inline-block"
                right="24px"
                top="24px"
                onClick={handleShowModal}
                cursor="pointer"
                background="none"
                border="none"
              >
                &#10005;
              </Flex>
            </Text>

            {sent ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                pb="48px"
                pt="24px"
              >
                <Text
                  padding="32px"
                  as="p"
                  color="EYOffBlack.default"
                  fontWeight="light"
                  fontSize={3}
                  lineHeight="1"
                >
                  {nbsp(
                    "Naši recruiteři se na to mrknou a až něco vymyslí, ozvou se ti."
                  )}
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexDirection={["column", null, null, "row", null]}
                  pb="18px"
                  pt="24px"
                >
                  <BtnComponent
                    mt={20}
                    href={URL.blog}
                    color={"button-outline-blue-dark"}
                    mr={[0, null, null, 20, null]}
                    width={["100%", null, null, "300px", null]}
                  >
                    Mezitím koukni na náš blog
                  </BtnComponent>
                  <Button
                    mt={20}
                    variant="outlineBlack"
                    type="submit"
                    onClick={() => {
                      handleShowModal();
                      setSent(false);
                    }}
                    width={["100%", null, null, "120px", null]}
                  >
                    Zavřít
                  </Button>
                </Flex>
              </Flex>
            ) : (
              ""
            )}

            {!sent && (
              <Formik
                initialValues={initialState}
                validationSchema={contactSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  if (values.recaptcha) {
                    setSubmitting(true);
                    let mailSendRequest = await ContactFormMutation(values);
                    if (mailSendRequest.msg !== "ok") {
                      setSubmitting(false);
                      return;
                    }
                    if (mailSendRequest.msg === "ok") {
                      window.dataLayer.push({
                        event: "form.sent",
                        form: "Kontakt",
                      });
                    }
                    resetForm(initialState);
                    setSent(true);
                    setSubmitting(false);
                  }
                }}
              >
                {({ setFieldValue, handleSubmit, submitForm }) => (
                  <Form
                    style={{
                      marginBottom: 0,
                      maxHeight: "calc(100vh - 160px)",
                      overflowY: "auto",
                    }}
                  >
                    <Flex
                      flexDirection="column"
                      paddingLeft={["24px", null, null, "48px", null]}
                      paddingRight={["24px", null, null, "48px", null]}
                      paddingTop={["24px", null, null, null, null]}
                      paddingBottom={["24px", null, null, null, null]}
                      justifyContent="stretch"
                    >
                      <Label as="label" display="block">
                        {nbsp("Tvoje jméno a příjmení")}
                        <Field
                          type="text"
                          name="name"
                          placeholder=""
                          label=""
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Label>
                      <Label as="label" display="block">
                        Tvoje e-mailová adresa
                        <Field
                          type="email"
                          name="email"
                          placeholder="@"
                          label="@"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Label>
                      <Label as="label" display="block">
                        Oddělení, kde chceš pracovat
                        <Field as="select" name="department">
                          <option value="">Vyber oddělení</option>
                          <option value="Audit">Audit</option>
                          <option value="Business Consulting">
                            Business Consulting
                          </option>
                          <option value="Daně">Daně</option>
                          <option value="Forenzní služby">
                            Forenzní služby
                          </option>
                          <option value="Právo">Právo</option>
                          <option value="Strategie a transakce">
                            {nbsp("Strategie a transakce")}
                          </option>
                          <option value="Technology Consulting">
                            Technology Consulting
                          </option>
                        </Field>
                      </Label>
                      <Label as="label" display="block">
                        Město, ve kterém chceš pracovat
                        <Field as="select" name="city">
                          <option value="">Vyber město</option>
                          <option value="Praha">Praha</option>
                          <option value="Brno">Brno</option>
                          <option value="Ostrava">Ostrava</option>
                        </Field>
                      </Label>
                      <Label as="label" display="block">
                        {nbsp("Chceš nám k tomu něco napsat?")}
                        <Field
                          as="textarea"
                          name="description"
                          placeholder="Třeba co studuješ, jaká oblast tě zajímá, jaké máš zkušenosti ..."
                        />
                      </Label>
                      <Label as="label" display="block">
                        Jestli máš LinkedIn profil, nech nám na něj odkaz
                        <Field
                          type="text"
                          name="linkedinLink"
                          placeholder="Odkaz"
                          label=""
                        />
                      </Label>
                      <Label as="label" display="block">
                        Přilož svůj životopis
                        <input
                          type="file"
                          name="biography"
                          id="biography"
                          onChange={(event) =>
                            setFieldValue(
                              "biography",
                              event.currentTarget.files[0]
                            )
                          }
                          multiple={false}
                        />
                        <ErrorMessage
                          name="biography"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Label>
                      <Recaptcha
                        sitekey="6LfzGVMdAAAAAEHifCwhOri6jziPHeMxx-shJGnt"
                        //TEST / DEV sitekey sitekey="6Ld74FgeAAAAAGYucrbjqZcDoiMfbz6qhshWvqrn"
                        ref={(element) => (recaptchaInstance = element)}
                        verifyCallback={(response) => {
                          setFieldValue("recaptcha", response);
                          handleSubmit();
                        }}
                        onloadCallback={() => {
                          console.log("done loading!");
                        }}
                        hl="cs"
                        size="invisible"
                      />
                      <Button
                        variant="fullYellow"
                        type="submit"
                        width="140px"
                        mb={["14px", null, null, null, null]}
                        onClick={executeCaptcha}
                      >
                        Odeslat
                      </Button>
                      <Checkbox>
                        <Label
                          as="label"
                          color="EYOffBlack.default"
                          fontSize="14px"
                          lineHeight="24px"
                        >
                          Potvrzuji, že jsem se seznámil/a s&nbsp;
                          <Link
                            fontSize={["12px", null, "14px", null, null]}
                            href={`${URL.gdpr}`}
                            underline
                            zIndex={33}
                            target={"_blank"}
                          >
                            {nbsp("Oznámením o ochraně osobních údajů")}
                          </Link>
                          .
                          <Field type="checkbox" name="gdpr" />
                          <Text
                            as="span"
                            style={{ border: "1px solid #c4c4cd" }}
                          />
                          <ErrorMessage
                            name="gdpr"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </Label>
                      </Checkbox>
                    </Flex>
                  </Form>
                )}
              </Formik>
            )}
          </Flex>
        </Flex>
      </Transition>
      <Flex
        zIndex="1010"
        position="fixed"
        width="100%"
        height="100%"
        top="0"
        left="0"
        background="rgba(46, 46, 56, 0.9)"
      />
    </Transition>
  );
};

export default ContactForm;
