import styled from "styled-components";
import { flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

import {
  space,
  color,
  layout,
  typography,
  background,
  position,
} from "styled-system";

export const Dl = styled.dl`
  margin: 0;
  margin-top: auto;
  padding: 0;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
`;

export const Dt = styled.dt`
  margin: 0;
  padding: 0;
  width: 30%;
  font-size: ${themeGet("fontSizes.1")};
  font-weight: normal;
  color: ${themeGet("colors.EYOffBlack.default")};
  opacity: 0.6;
  position: relative;
`;

export const Dd = styled.dd`
  margin: 0;
  padding: 0;
  margin-left: auto;
  width: 70%;
  font-size: ${themeGet("fontSizes.1")};
  color: ${themeGet("colors.EYOffBlack.default")};
  position: relative;
`;
