import React, { useState, useEffect, useContext, useMemo } from "react";
import { graphql } from "gatsby";
import produce from "immer";

import Layout from "../components/Layout";
import Header from "../components/Header";
import Card from "../components/Card/Card";
import JobContent from "../components/Card/JobContent";
import TopicLinks from "../components/TopicLinks";
import TileButton from "../components/TileButton";
import Button from "../components/Button";
import CareerGrowth from "../components/CareerGrowth";

import { Flex } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import TriangleFilter from "../style/Header/triangleFilter";
import Container from "../style/Grid/Container";
import { ButtonFilter } from "../style/ButtonFilter";
import { URL, JOBS_PER_PAGE } from "../constants";
import { slugify } from "../utils/slugify";
import FilterSidebar from "../components/Filter/FilterSidebar";
import usePageView from "../utils/usePageView";
import ContactForm from "../components/ContactForm";
import SEO from "../components/seo";
import { filterContext } from "../utils/filterStateProvider";

import nbsp from "../utils/nbsp";
import { shuffleArray } from "../utils/shuffleArray";

const Job = ({ data, location }) => {
  const careerGrowthData = [
    {
      position: "Intern",
      description: "Při škole",
      text: "Chvíli v práci, chvíli ve škole. Přidat se k nám můžeš už jako student. Nebudeš stát stranou, zapojíme tě rovnou do projektů. Zvládat se to dá, nemusíš mít obavy. Získáš smysluplnou praxi  a uděláš si dokonalou představu, o čem je poradenství EY.",
      image: data.careerImage1,
    },
    {
      position: "Junior konzultant",
      description: "Hned po škole",
      text: "Projekty, školení, projekty, školení. Od začátku se budeš hodně učit, plnit dílčí úkoly, konzultovat s týmem a chodit na schůzky s klienty. Budeš hodně pracovat, ale určitě si najdeš čas na koníčky. Poznáš spoustu kolegů, ze kterých se brzy stanou skvělí kamarádi.",
      image: data.careerImage2,
    },
    {
      position: "Senior konzultant",
      description: "Za 2 - 3 roky",
      text: "Dostaneš na starost tým juniorů a přijdou nové, komplikovanější projekty. Začneš ve větším cestovat po Česku i do ciziny a stane se z tebe skutečný odborník, jehož slovo má váhu. Budeš mít větší zodpovědnost jak vůči kolegům, tak klientům.",
      image: data.careerImage3,
    },
    {
      position: "Manager",
      description: "Za 5 - 6 let",
      text: "Poradenství bude tvůj životní styl. Budeš perfektně znát procesy, mít dokonalý přehled o dění v týmu a každý den nahlédneš do firem z různých odvětví. Staneš se tváří našich projektů a postupně začneš rozvíjet kontakty a přinášet do EY byznys.",
      image: data.careerImage4,
    },
    {
      position: "Senior manager",
      description: "Po několika letech jako manažer",
      text: "Budeš mít komplexní práci. Od kontaktů s klienty přes vyhledávání nového byznysu až po zodpovědnost za kvalitu projektů a řízení celého týmu. Budeš předávat své zkušenosti kolegům a spolupodílet se na strategických rozhodnutích firmy.",
      image: data.careerImage5,
    },
    {
      position: "Partner",
      description: "Možná jednou",
      text: "Tvoje role bude víc strategická a obchodní. Dohlédneš na fungování týmu a spokojenost klientů. Budeš hledat dlouhodobé trendy, přednášet o nich, školit kolegy, domlouvat nové projekty a řídit směrování poradenství v Česku i v rámci celého EY.",
      image: data.careerImage6,
    },
  ];

  usePageView(location.pathname, "Volné pozice", "");

  const { setJobsFilter, setJobsLimit, jobsFilter, jobsLimit } =
    useContext(filterContext);

  const [jobs, setJobs] = useState(data.allMdx.nodes);
  const [filteredJobs, setFilteredJobs] = useState(jobs);

  useEffect(() => {
    const j = shuffleArray(data.allMdx.nodes);
    setJobs(j);
    setFilteredJobs(j);
  }, []);

  const regions = data.allRegions.nodes;
  const [filterShow, setShowFilter] = useState(false);
  const [nextButtonShow, setShowNextButton] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(!showModal);

  const resetFilter = () => {
    setJobsFilter({});
  };

  useEffect(() => {
    if (location.search) {
      let filter = location.search
        .slice(1)
        .split("&")
        .map((p) => p.split("="))
        .reduce((obj, pair) => {
          const [key, value] = pair.map(decodeURIComponent);
          return { ...obj, [key]: value.split(",") };
        }, {});
      if (filter?.jobDepartment) {
        if (filter?.jobDepartment.includes("assurance")) {
          filter.jobDepartment = [
            ...filter.jobDepartment,
            "forenzni-sluzby",
            "audit",
          ];
          delete filter.jobDepartment[
            filter.jobDepartment.indexOf("assurance")
          ];
        }
        if (filter?.jobDepartment.includes("consulting")) {
          filter.jobDepartment = [
            ...filter.jobDepartment,
            "business-consulting",
            "technology-consulting",
          ];
          delete filter.jobDepartment[
            filter.jobDepartment.indexOf("consulting")
          ];
        }
        filter.jobDepartment = filter.jobDepartment.filter((x) => x);
      }
      setJobsFilter(filter);
    }
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const nextState = produce(jobsFilter, (draftState) => {
      let index = draftState[name] && draftState[name].indexOf(value);
      const slugifiedIndex =
        draftState[name] && draftState[name].indexOf(slugify(value));
      if (draftState[name] && (index > -1 || slugifiedIndex > -1)) {
        if (index > -1) {
          draftState[name].splice(index, 1);
        }
        if (slugifiedIndex > -1) {
          draftState[name].splice(slugifiedIndex, 1);
        }
      } else if (draftState[name] && index < 0 && slugifiedIndex < 0) {
        window.dataLayer.push({
          event: "job.filter",
          filterName: event.target.dataset.filtertype,
          filterValue: event.target.dataset.filtervalue,
        });
        draftState[name].push(value);
      } else {
        window.dataLayer.push({
          event: "job.filter",
          filterName: event.target.dataset.filtertype,
          filterValue: event.target.dataset.filtervalue,
        });
        draftState[name] = [value];
      }
    });
    setJobsFilter(nextState);
  };

  const handleShowFilter = () => {
    const el = document.querySelector(".fb_reset");
    if (el) {
      if (filterShow) {
        el.style.display = "block";
      } else {
        el.style.display = "none";
      }
    }
    setShowFilter(!filterShow);
  };
  const handleJobLimit = () => setJobsLimit(jobsLimit + JOBS_PER_PAGE);

  useEffect(() => {
    setFilteredJobs(jobs.filter((job) => {
      let isValid = true;

      const {
        jobAim,
        jobContractType,
        jobSuitableFor,
        jobDepartment,
        jobLocality,
        region,
      } = job.frontmatter;

      for (const [key, value] of Object.entries(jobsFilter)) {
        if (!isValid) break;
        if (Array.isArray(value) && value.length > 0) {
          switch (key) {
            case "experienceRequired":
              if (!value.some((e) => jobSuitableFor.includes(e))) {
                isValid = false;
              }
              break;
            case "jobAim":
              if (
                !value.some((e) =>
                  jobAim
                    .map((j) => slugify(j))
                    .includes(slugify(e).toLowerCase())
                )
              ) {
                isValid = false;
              }
              break;
            case "jobContractType":
              if (!value.some((e) => jobContractType.includes(e))) {
                isValid = false;
              }
              break;
            case "jobDepartment":
              const slugifiedDepartments = jobDepartment.map((j) => slugify(j));
              if (
                !value.some(
                  (e) =>
                    jobDepartment.includes(e) ||
                    slugifiedDepartments.includes(slugify(e))
                )
              ) {
                isValid = false;
              }
              break;
            case "jobLocality":
              let localityValid = false;
              value.forEach((e) => {
                const [city, regionID] = e.split(",");
                if (
                  jobLocality
                    ?.map((j) => j.toLowerCase())
                    .includes(city.toLowerCase()) ||
                  region === regionID
                ) {
                  localityValid = true;
                }
              });

              if (!localityValid) {
                isValid = false;
              }
              break;
            default:
              break;
          }
        }
      }

      return isValid;
    }));
  }, [jobsFilter]);

  let slicedJobs = filteredJobs.slice(0, jobsLimit);

  useEffect(() => {
    if (slicedJobs.length === filteredJobs.length) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  }, [jobsFilter, jobsLimit]);

  return (
    <Layout title="job" location={location}>
      <SEO title={"Volné pozice"} description={"Koho aktuálně hledáme?"} />
      {!filterShow && (
        <>
          <Header
            bgImage={data.headerImage}
            bottomComponents={<TriangleFilter />}
            minHeight={["430px", null, "545px", null, null]}
            shadowAfter={true}
            containerJustifyContent="flex-end"
          >
            <Heading
              as="h1"
              color="white.100"
              fontSize={[5, 6, 6, "48px", null]}
              fontWeight="300"
              lineHeight="1.3"
              maxWidth={["350px", "500px", "700px", null, null]}
              mb={["24px", null, null, null, null]}
            >
              {nbsp("Najdi si pozici, která ti nejvíc sedne")}
            </Heading>
            <Text
              color="white.100"
              fontWeight="300"
              fontSize={[2, null, null, 2, null]}
              lineHeight="24px"
              maxWidth={["230px", "300px", "350px", "400px", null]}
              mb={["45px", null, "84px", 6, null]}
            >
              {nbsp(
                "Projdi si inzeráty, vyber ten správný a vydej se na cestu s EY."
              )}
            </Text>
          </Header>
          <Flex
            display={["flex", null, null, "none", null]}
            backgroundColor="EYYellow.default"
            height="80px"
            px="32px"
            py="16px"
          >
            <ButtonFilter onClick={handleShowFilter}>
              Filtrovat ({filteredJobs.length})
            </ButtonFilter>
          </Flex>
        </>
      )}
      <Flex
        flexDirection={["column", null, "row", null, null]}
        background={[
          "none",
          null,
          null,
          `linear-gradient(90deg, #ffe600 31.25%, #FFF 31.25%)`,
          null,
        ]}
      >
        <Flex width={["100%", null, null, null, "1217px"]} margin="0 auto">
          <FilterSidebar
            jobsLimit={jobsLimit}
            filterShow={filterShow}
            resetFilter={resetFilter}
            handleChange={handleChange}
            filterData={jobsFilter}
            handleShowFilter={handleShowFilter}
            type="job"
          />

          {!filterShow && (
            <Flex width={["100%", null, "100%", "62%", "68.75%"]}>
              <Flex
                maxWidth={["100%", null, "100%", "calc(100% - 81px)", "90%"]}
                width={[
                  "calc(100% - 31px)",
                  null,
                  null,
                  "calc(100% - 81px)",
                  "100%",
                ]}
                flexDirection="row"
                flexWrap="wrap"
                alignContent={slicedJobs.length > 0 ? "flex-start" : "center"}
                justifyContent={[
                  "center",
                  "flex-start",
                  "space-between",
                  null,
                  null,
                ]}
                ml={
                  slicedJobs.length > 0
                    ? ["16px", null, null, "50px", "10%"]
                    : ["32px", null, null, "50px", "10%"]
                }
                mr={
                  slicedJobs.length > 0
                    ? ["16px", null, null, "30px", 0]
                    : ["32px", null, null, "30px", 0]
                }
                mb={
                  slicedJobs.length > 0
                    ? ["64px", null, null, null, null]
                    : ["32px", null, null, null, null]
                }
                mt={
                  slicedJobs.length > 0
                    ? ["16px", null, null, 0, null]
                    : ["32px", null, null, 0, null]
                }
                id="blogContent"
              >
                {slicedJobs.length > 0 ? (
                  slicedJobs.map((node) => {
                    return (
                      <Card
                        key={node.fields.slug}
                        link={`${URL.jobs}${node.fields.slug.substr(
                          0,
                          node.fields.slug.length - 1
                        )}-${slugify(node.frontmatter.title)}`}
                        content={node.frontmatter}
                        image={node.frontmatter.jobHeaderImageFile}
                        type="job"
                      >
                        <JobContent
                          job={node.frontmatter}
                          region={regions.find(
                            (r) =>
                              String(r.frontmatter.id) ===
                              node.frontmatter.region
                          )}
                        />
                      </Card>
                    );
                  })
                ) : (
                  <Container
                    width={["100%", null, null, null, null]}
                    flexDirection="column"
                    px={null}
                    pt={null}
                    pb={null}
                  >
                    <Heading
                      as="h2"
                      color="EYBlack.default"
                      fontSize={["24px", null, null, null, "32px"]}
                      lineHeight={["28px", null, null, null, "40px"]}
                      fontWeight="400"
                      mb="16px"
                      textAlign={["left", null, null, "center", null]}
                    >
                      {nbsp(
                        "Takovou pozici otevřenou nemáme, ale tvoje cesta s EY tím nekončí"
                      )}
                    </Heading>
                    <Text
                      width={["100%", null, null, null, null]}
                      color="EYBlack.default"
                      fontWeight="light"
                      fontSize={[2, null, null, 2, null]}
                      lineHeight="24px"
                      maxWidth={[null, null, null, null, "688px"]}
                      textAlign={["left", null, null, null, "center"]}
                      mx={[null, null, null, null, "auto"]}
                    >
                      {nbsp(
                        "Na zadané parametry filtru jsme pro tebe aktuálně nic vhodného nenašli. Ale nezoufej, protože pořád rosteme a nové lidi do týmu potřebujeme často. Nech nám na sebe kontakt a my se ti ozveme, až podobnou pozici otevřeme."
                      )}
                    </Text>
                    <Flex justifyContent="center" mt="40px">
                      <Button
                        as="button"
                        variant="outlineBlack"
                        fontWeight="300"
                        fontSize="14px"
                        onClick={handleShowModal}
                      >
                        {nbsp("Zůstat v kontaktu")}
                      </Button>
                    </Flex>
                  </Container>
                )}

                {nextButtonShow && (
                  <Flex
                    alignContent="center"
                    justifyContent="center"
                    width="100%"
                  >
                    <Button
                      as="button"
                      variant="outlineBlack"
                      maxWidth="300px"
                      width={["264px", "280px", null, null, null]}
                      fontWeight="300"
                      fontSize="14px"
                      marginTop={"30px"}
                      marginBottom={["64px", null, null, "120px", null]}
                      onClick={handleJobLimit}
                    >
                      Další volné pozice
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>

      {!filterShow && (
        <>
          <TopicLinks
            as="section"
            mt="0"
            items={[
              {
                heading: "Výběrko do EY očima nováčků",
                link: `${URL.blog}/vyberko-do-ey-ocima-novacku`,
                image: data.topicLinksImage1,
              },
              {
                heading: "4 rady, jak zvládnout pohovor do EY",
                link: `${URL.blog}/60-4-rady-jak-zvladnout-pohovor-do-ey`,
                image: data.topicLinksImage2,
              },
            ]}
          >
            Možná ti přijde vhod
          </TopicLinks>

          <section>
            <Flex>
              <TileButton
                to={URL.jobs}
                width="100%"
                tileColor="light"
                tileOrientation="right"
                onClick={handleShowModal}
              >
                <Flex flexDirection="column" alignItems="flex-end">
                  <Text
                    fontSize={["18px", null, "5", null, null]}
                    color="EYOffBlack.tile"
                  >
                    Chybí ti tu pozice přesně pro tebe?
                  </Text>
                  <Text
                    fontSize={[1, null, 2, null, null]}
                    fontWeight="300"
                    color="EYOffBlack.tile"
                    opacity=".7"
                    mt={["8px", null, "24px", null, null]}
                  >
                    {nbsp("Nech nám na sebe kontakt a probereme to osobně.")}
                  </Text>
                </Flex>
              </TileButton>
            </Flex>
          </section>
          <CareerGrowth careers={careerGrowthData} />
        </>
      )}

      {showModal && (
        <ContactForm handleShowModal={handleShowModal} showModal={showModal} />
      )}
    </Layout>
  );
};

export default Job;

export const pageQuery = graphql`
  query jobQuery {
    allRegions: allMdx(
      filter: { frontmatter: { templateKey: { eq: "region" } } }
    ) {
      nodes {
        frontmatter {
          title
          id
        }
      }
    }
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "job-post" }, publish: { eq: true } }
      }
    ) {
      nodes {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          templateKey
          jobContractType
          jobTeam
          jobAim
          jobDepartment
          jobSuitableFor
          jobLocality
          experienceRequired
          region
          jobHeaderImageFile {
            childImageSharp {
              gatsbyImageData(width: 407, height: 333, quality: 90)
            }
          }
        }
      }
    }
    headerImage: file(
      relativePath: { eq: "header-backgrounds/volne-pozice-bg.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage1: file(
      relativePath: { eq: "vyberko-do-ey-ocima-novacku.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage2: file(
      relativePath: { eq: "4-rady-jak-na-vyberko-do-ey.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    careerImage1: file(relativePath: { eq: "career/1.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1350, quality: 90, layout: FIXED)
      }
    }
    careerImage2: file(relativePath: { eq: "career/2.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage3: file(relativePath: { eq: "career/3.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage4: file(relativePath: { eq: "career/4.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1350, quality: 90, layout: FIXED)
      }
    }
    careerImage5: file(relativePath: { eq: "career/5.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage6: file(relativePath: { eq: "career/6.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
  }
`;
