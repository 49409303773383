export const formatContractType = contractTypes => {
  const hasFull = contractTypes.includes("full");
  const hasHalf = contractTypes.includes("half");

  if (hasHalf && !hasFull) {
    return "Zkrácený úvazek";
  }

  return `${hasFull ? "Plný" : ""}${hasHalf ? " a zkrácený" : ""} úvazek`;
};

export const smoothScroll = (e, where, offset) => {
  e.preventDefault();
  scrollTo({
    top:
      document.querySelector(where).offsetTop -
      (offset && document.querySelector("nav").offsetHeight + 20),
    behavior: "smooth",
  });
};
