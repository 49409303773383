import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const Dl = styled.dl`
  margin: 0;
  margin-top: auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;

  & > div:first-of-type {
    width: 60%;
  }
  & > div {
    width: 30%;
  }
`;

export const DlBorder = styled(Dl)`
  margin-top: 0;
  div:first-of-type {
    width: initial;
    padding-right: 64px;
    margin-right: 64px;
    border-right: 1px solid rgba(255, 255, 255, 0.16);
    @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
      width: 100%;
      padding-right: 0;
      margin-right: 0;
      border-right: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      padding-bottom: 20px;
      margin-bottom: 16px;
    }
  }
  dt,
  dd {
    color: white;
  }

  dt {
    font-size: 12px;
    font-weight: 300;
  }

  dd {
    font-size: 18px;
    @media screen and (max-width: ${themeGet("breakpoints.md")}) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;

    & > div {
      width: 100%;
    }
  }
`;

export const Dt = styled.dt`
  margin: 0;
  padding: 0;
  width: 30%;
  font-size: ${themeGet("fontSizes.1")};
  line-height: 24px;
  font-weight: normal;
  color: ${themeGet("colors.EYOffBlack.default")};
  opacity: 0.8;
  position: relative;
  text-transform: uppercase;
  &:first-of-type {
    width: 100%;
  }
`;

export const Dd = styled.dd`
  margin: 0;
  padding: 0;
  width: 30%;
  max-width: 30%;
  font-size: ${themeGet("fontSizes.1")};
  line-height: 24px;
  color: ${themeGet("colors.EYOffBlack.default")};
  position: relative;
  &:first-of-type {
    width: 100%;
    max-width: 100%;
  }
`;
