import React from "react";

import { TileButton as TileButtonStyle } from "../../style/TileButton";
import { Image } from "../../style/Image";
import arrowDark from "../../images/arrow-dark.svg";
import arrowLight from "../../images/arrow-light.svg";

const TileButton = props => {
  const { to, tileColor = "light", tileOrientation = "left", children } = props;

  return (
    <TileButtonStyle
      {...props}
      to={to}
      type={tileColor}
      color={tileColor === "light" ? "EYOffBlack.tile" : "white.100"}
      bg={tileColor === "light" ? "EYYellow.default" : "EYOffBlack.tile"}
      flexDirection={tileOrientation === "left" ? "row-reverse" : "row"}
      justifyContent="flex-end"
      px={["15px", "30px", "50px", "100px", null]}
      fontSize={["2", "3", "5", null, null]}
      minHeight={["112px", null, "240px", null, null]}
    >
      {children}
      <Image
        height={["18px", null, "auto", null, null]}
        src={
          tileColor === "light"
            ? arrowDark
            : arrowLight
        }
        mr={tileOrientation === "left" ? ["20px", null, "50px", null, null] : 0}
        ml={tileOrientation === "left" ? 0 : ["20px", null, "50px", null, null]}
        css={{
          transform: tileOrientation === "left" ? "scaleX(-1)" : "none",
        }}
      />
    </TileButtonStyle>
  );
};

export default TileButton;
