import styled from "styled-components";
import { BackgroundImage } from "../../style/BackgroundImage/BackgroundImage";
import { HeadingWithLine } from "../../style/Heading/HeadingWithLine";
import { Dt, Dd } from "../Card/Primitives";
import {
  Dt as DtLandscape,
  Dd as DdLandscape,
} from "../Card/PrimitivesLandscape";

import { Link } from "../../style/Link/index";
import { themeGet } from "@styled-system/theme-get";

const CardImage = styled(Link)`
    position: relative;
    flex-direction: column;
    border: 1px solid #c4c4cd;
    display: flex;
    cursor: pointer;

  & > * {
    z-index: 9;
  }

  @media (pointer: fine) {
    &:hover {
      text-decoration: none;
      ${Dt} {
          color: ${themeGet("colors.EYOffWhite")};
      }
      ${Dd} {
          color: ${themeGet("colors.EYOffWhite")};
      }
      ${DtLandscape} {
          color: ${themeGet("colors.EYOffWhite")};
      }
      ${DdLandscape} {
          color: ${themeGet("colors.EYOffWhite")};
      }
      ${BackgroundImage} {
          display: block;
          opacity: 1;
      }
      ${HeadingWithLine} {
         color: ${themeGet("colors.EYOffWhite")};
         :after {
          width: 100%;
      }
    }
  }
`;

export { CardImage };
