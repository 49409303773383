import React from "react";
import { Dl, Dt, Dd } from "./Primitives";
import { formatContractType } from "../helperFunctions";

const JobContent = ({ job, region, ...props }) => {
  return (
    <Dl>
      <Dt>Typ úvazku</Dt>
      <Dd>{formatContractType(job.jobContractType)}</Dd>
      <Dt>Lokalita</Dt>
      <Dd>{job.jobLocality.join && job.jobLocality.join()}{job.jobLocality.length > 0 && region ? `, ${region.frontmatter.title}` : region ? region.frontmatter.title : ''}</Dd>
      <Dt>Oddělení</Dt>
      <Dd>{job.jobDepartment.join(", ")}</Dd>
      {job?.jobTeam && (
        <>
          <Dt>Tým</Dt>
          <Dd>{job.jobTeam}</Dd>
        </>
      )}
      <Dt>Zaměření</Dt>
      <Dd>{job.jobAim.join(", ")}</Dd>
    </Dl>
  );
};

export default JobContent;
