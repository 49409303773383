import styled from "styled-components";
import { space, color, layout, typography, flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";

import { Link as GatsbyLink } from "gatsby";
import { themeGet } from "@styled-system/theme-get";

const TileButton = styled(GatsbyLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color ${themeGet("times.transition")};

  ${space}
  ${flexbox}
  ${color}
  ${layout}
  ${typography}

  background-color: ${props => props.tileColor};

  ${props =>
    props.tileColor === "light" &&
    `
    &:hover {
      background-color: ${props.theme.colors.EYYellow.hover};
    }
  `}

  ${props =>
    props.tileColor === "dark" &&
    `
    &:hover {
      background-color: ${props.theme.colors.EYOffBlack.hover};
    }
  `}

  & > div > p {
    text-align: right;
  }
`;

TileButton.displayName = `TileButton`;

TileButton.defaultProps = {
  width: "100%",
  justifyContent: "flex-start",
  lineHeight: "1",
  fontSize: "5",
  fontWeight: "light",
};

TileButton.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
};

export { TileButton };
