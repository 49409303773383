import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { space, color, layout, typography } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { Heading } from "./index";

const HeadingWithLine = styled(Heading)`
  position: relative;
  &:after {
    transition: "all .1s ease-out";
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 3px;
    width: 64px;
    background: ${themeGet("colors.EYYellow.default")};
  }
`;
export { HeadingWithLine };
