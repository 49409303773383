import styled from "styled-components";
import { space, color, layout, typography } from "styled-system";
import propTypes from "@styled-system/prop-types";

const Label = styled.label`
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-bottom: 14px;
  font-weight: 300;
  input {
    display: block;
    width: 100%;
    height: 48px;
    line-height: 48px;
    border: 1px solid #c4c4cd;
    font-size: 16px;
    margin-top: 10px;
    padding-left: 12px;
    font-weight: 400;

    &[type="file"] {
      border: 0;
      padding-left: 0;
      height: auto;
      font-size: 14px;
      line-height: 20px;
    }
  }
  select {
    display: block;
    width: 100%;
    height: 48px;
    line-height: 48px;
    border: 1px solid #c4c4cd;
    font-size: 16px;
    margin-top: 10px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 400;
    -webkit-appearance: none;
  }
  textarea {
    display: block;
    width: 100%;
    height: 144px;
    line-height: 20px;
    border: 1px solid #c4c4cd;
    font-size: 14px;
    margin-top: 10px;
    padding: 12px;
    font-weight: 400;
    resize: vertical;
  }
  ${space}
  ${color}
  ${layout}
  ${typography}
`;

Label.displayName = `Label`;

Label.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
};

export { Label };
