import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

const CareerGrowthImageWrapper = styled.div`
    position: absolute;
    top: 0;
    right: -2vw;
    bottom: 0;
    width: auto;
    min-height: 600px;
    max-width: 1920px;
    object-position: top right;

    @media screen and (max-width: ${themeGet("breakpoints.xl")}) {
      right: -30vw;
    }

    @media screen and (max-width: ${themeGet("breakpoints.md")}) {
      right: 0;
      left: -50vw;
      object-fit: cover;
      object-position: top center;
      min-height: 70%;
    }

    @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
      left: -100vw;
    }
`;

export { CareerGrowthImageWrapper };
