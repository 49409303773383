import styled from "styled-components";
import { space, layout, border } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { Box } from "../Grid";

export const BackgroundImage = styled(Box)(
  {
    width: "calc(100% + 64px)",
    height: "calc(100% + 64px)",
    position: "absolute",
    top: "-32px",
    left: "-32px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    blacklist: ["src"],
    transition: "all 0.2s ease-in",
    opacity: 0,
    zIndex: 1,
  },

  (props) => ({
    backgroundImage: props.src
      ? `linear-gradient(180deg, rgba(46, 46, 56, 0.1) 0%, #2e2e38 100%), url(${props.src})`
      : undefined,
  })
);
BackgroundImage.displayName = "BackgroundImage";

/*

const BackgroundImage = styled(Box)`
  width: calc(100% + 64px);
  height: calc(100% + 64px);
  position: absolute";
  top: -32px;
  left: -32px;
  backgroundSize: cover;
  backgroundPosition: center;
  blacklist: ["src"];
  transition: all 0.2s ease-in;
  opacity: 0;
  zIndex: 1;

  ${(props) =>
    props.src &&
    `
      backgroundImage: linear-gradient(180deg, rgba(46, 46, 56, 0.1) 0%, #2e2e38 100%), url(${props.src});
    `}
`;
BackgroundImage.displayName = "BackgroundImage";

export { BackgroundImage };


*/
