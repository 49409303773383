import React, { useState, Fragment } from "react";
import { Box, Flex, Wrapper } from "../../style/Grid";
import {
  CareerGrowthChangerButton,
  CareerGrowthChangerItem,
} from "../../style/CareerGrowth";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { CareerGrowthImageWrapper } from "../../style/CareerGrowthImageWrapper";
import nbsp from "../../utils/nbsp";

const CareerItem = (props) => {
  const { active, position, description, text, image } = props;

  return (
    <CareerGrowthChangerItem active={active}>
      {image?.childImageSharp && (
        <CareerGrowthImageWrapper>
          <Image image={image} />
        </CareerGrowthImageWrapper>
      )}
      <Box
        zIndex={1}
        position="relative"
        width="100%"
        height="100%"
        m="0 auto"
        color="white.100"
      >
        <Flex
          flexDirection="column"
          position="absolute"
          bottom={["120px", null, "50px"]}
          width={["100%", null, "500px", "580px", "580px"]}
          px={["31px", null, null, null, "50px"]}
        >
          <Heading
            as="h3"
            color="EYYellow.default"
            fontSize={[7, null, "64px", null, "72px"]}
            fontWeight="light"
          >
            {nbsp(position)}
          </Heading>
          <Text as="p" color="EYGrey01" fontSize={1} my={3} fontWeight="light">
            {nbsp(description.toUpperCase())}
          </Text>
          <Text as="p" color="white.100" fontSize={2} fontWeight="light">
            {nbsp(text)}
          </Text>
        </Flex>
      </Box>
    </CareerGrowthChangerItem>
  );
};

const CareerGrowth = ({ careers }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [hoverSlide, setHoverSlide] = useState(false);

  return (
    <Wrapper as="div" bg="EYOffBlack.default" id="karierni-rust">
      <Box
        minHeight={["830px", null, 600, null, null]}
        position="relative"
        overflow="hidden"
        width={["100%", null, null, null, null]}
        maxWidth={"1920px"}
        px={["31px", null, null, null, "50px"]}
      >
        <Heading
          as="h2"
          color="white.100"
          fontSize={[4, null, 5, null, 6]}
          fontWeight="light"
          position="relative"
          top={50}
          zIndex={30}
          width="100%"
        >
          {nbsp("Jak u nás funguje kariérní růst?")}
        </Heading>

        {typeof hoverSlide === "number" ? (
          <Fragment>
            {careers?.map((item, index) => (
              <CareerItem key={index} {...item} active={hoverSlide === index} />
            ))}
          </Fragment>
        ) : (
          <Fragment>
            {careers?.map((item, index) => (
              <CareerItem
                key={index}
                {...item}
                active={activeSlide === index}
              />
            ))}
          </Fragment>
        )}

        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          width={["100%", null, null, null, null]}
          maxWidth={"1920px"}
          height="100%"
          margin="0 auto"
          style={{ pointerEvents: "none" }}
        >
          <Box
            position="relative"
            width="100%"
            height="100%"
            color="white.100"
            zIndex={50}
          >
            <Flex
              flexDirection={["row", null, "column-reverse"]}
              justifyContent="space-between"
              zIndex={50}
              position="absolute"
              top={["auto", null, 0]}
              right={[0, null, null, null, "50px"]}
              bottom={[20, null, 0]}
              width={["100%", null, 250]}
              m={[null, null, "70px 0"]}
              style={{ pointerEvents: "all" }}
              overflow="auto"
              px={["31px", null, null, null, 0]}
            >
              {careers?.map((item, index) => (
                <CareerGrowthChangerButton
                  active={activeSlide === index}
                  onClick={() => setActiveSlide(index)}
                  onMouseLeave={() => setHoverSlide(false)}
                  onMouseEnter={() => setHoverSlide(index)}
                >
                  <strong>{item.position}</strong>
                  <span>
                    <b>{index + 1}</b>
                  </span>
                </CareerGrowthChangerButton>
              ))}
            </Flex>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default CareerGrowth;
