import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

const CareerGrowthChangerItem = styled.div`
  opacity: ${(props) => (props.active ? "1" : "0")};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 1000ms;

  &:after {
    content: "";
    background-image: linear-gradient(
        90deg,
        #2e2e38 35%,
        rgba(46, 46, 56, 0) 100%
      ),
      linear-gradient(
        90deg,
        rgba(46, 46, 56, 0) 80%,
        rgba(46, 46, 56, 0.4) 100%
      );
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 1920px;
    margin: 0 auto;

    @media screen and (max-width: ${themeGet("breakpoints.md")}) {
      background-image: linear-gradient(
          0deg,
          #2e2e38 30%,
          rgba(46, 46, 56, 0) 100%
        ),
        linear-gradient(
          270deg,
          rgba(46, 46, 56, 0) 0%,
          rgba(46, 46, 56, 0.2) 100%
        );
    }
  }
`;

const CareerGrowthChangerButton = styled.button`
  position: relative;
  z-index: 55;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  font-size: ${themeGet("fontSizes.2")};
  text-align: right;
  border: 0;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  &:last-of-type {
    span {
      &:after {
        display: none;
      }
    }
  }
  @media (pointer: fine) {
    &:hover {
      strong {
        color: ${(props) =>
          props.active
            ? themeGet("colors.EYYellow.default")
            : themeGet("colors.white.100")};
      }
      span {
        color: ${(props) =>
          props.active
            ? themeGet("colors.EYYellow.default")
            : themeGet("colors.white.100")};
        border-color: ${(props) =>
          props.active
            ? themeGet("colors.EYYellow.default")
            : themeGet("colors.white.100")};
      }
    }
  }
  strong {
    font-weight: ${themeGet("fontWeights.light")};
    color: ${(props) =>
      props.active
        ? themeGet("colors.EYYellow.default")
        : themeGet("colors.white.70")};
    transition: color 400ms;
  }
  span {
    position: relative;
    display: block;
    width: 35px;
    height: 35px;
    margin-left: 20px;
    border-radius: 100%;
    border: 2px solid
      ${(props) =>
        props.active
          ? themeGet("colors.EYYellow.default")
          : themeGet("colors.white.70")};
    color: ${(props) =>
      props.active
        ? themeGet("colors.EYYellow.default")
        : themeGet("colors.white.70")};
    transition: color 400ms, border 400ms;
    &:after {
      content: "";
      position: absolute;
      top: -46px;
      left: 15px;
      width: 1px;
      height: 38px;
      background-color: ${themeGet("colors.white.50")};
    }
    b {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
      font-weight: ${themeGet("fontWeights.light")};
    }
  }

  @media screen and (max-width: 767px) {
    position: relative;
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    padding-bottom: 30px;
    width: 150px;
    min-width: 150px;
    strong {
      position: absolute;
      top: 40px;
      left: 50%;
      white-space: nowrap;
      line-height: 1.2;
      transform: translate(-50%, 0);
    }
    span {
      margin-left: 0;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 40px;
        width: 100px;
        height: 2px;
        transform: translateY(-50%);
        background-color: ${themeGet("colors.white.70")};
      }
    }
    &:first-of-type {
      margin-left: -50px;
    }
    &:last-of-type {
      margin-right: -50px;
    }
  }
`;

export { CareerGrowthChangerItem, CareerGrowthChangerButton };
